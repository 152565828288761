#conflict-table {
    width: 100%;
}

#conflict-table *:not(.actionclass *) {
    padding: 0px 5px;
    margin: 0px;
}

#conflict-table .w300{
    min-width: 300px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
