.otp-verification-page {
  height: 100vh;
  background: rgb(19, 47, 189);
  background-image: url("/public/opt_background.png");
  background-position: center;
  background-size: cover;
}

.otp-verification-page #otptext{
  letter-spacing: 15px;
  text-align: center;
}

.inputs input {
  box-shadow: none ! important;
  outline: none ! important;
  border: 1px solid #ced4da;
  width: 40px;
  height: 40px;
  margin: 10px 3px 3px 3px;
  box-sizing: border-box;
  font-size: 1rem;
  text-align: center;
  border-radius: 0.25rem;
}

.inputs input:focus {
  box-shadow: none;
  border: 2px solid red
}

.inputs input[type=text]::-webkit-inner-spin-button,
.inputs input[type=text]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0
}