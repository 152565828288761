.main-frame {
    min-height: 80vh;
    margin-top: 5px;
}

.main-frame-min {
    min-height: 70vh;
    margin-top: 5px;
}

.icontext {
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 340px) {
    .brand-name {
        display: none;
    }
}

@media only screen and (max-width: 400px) {
    .brand-name {
        zoom: 0.7;
    }
}