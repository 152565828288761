.game-list-200-item {
    padding: 10px;
}

.game-list-200-item table th {
    text-align: left;
    font-weight: 500;
}

.game-list-200-item table td {
    text-align: right;
}

.game-list-200-item .names {
    margin: 0px 5px;
    cursor: pointer;
}

.game-list-200-item .ss {
    margin: 0px 5px;
    cursor: pointer;
    max-width: 100px;
}
