.marqueebox {
    max-width: 100%;
    overflow: hidden;
}

.marqueebox .marquee {
    white-space: nowrap;
    overflow: hidden;
    font-weight: bold;
    box-sizing: border-box;
    padding: 10px 0 5px 0;
    display: inline-block;
    padding-left: 100%;
    animation: marquee 15s linear infinite;
}

@keyframes marquee {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(-100%, 0);
    }
}