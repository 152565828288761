.not-found {
    background-color: #2F3242;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    flex-direction: row;

}
.not-found h1{
    line-height: 1rem;
}
.not-found .buttons-con .action-link-wrap {
    margin-top: 40px;
}

.not-found .buttons-con .action-link-wrap a {
    background: #68c950;
    padding: 8px 25px;
    border-radius: 4px;
    color: #FFF;
    font-size: 1rem;
    transition: all 0.3s linear;
    cursor: pointer;
    text-decoration: none;
    margin-right: 10px
}

.not-found .buttons-con .action-link-wrap a:hover {
    background: #5A5C6C;
    color: #fff;
}

.not-found img {
    animation: float 1s infinite ease-in-out alternate;
}

@keyframes float {
    100% {
        transform: translateY(20px);
    }
}
@media (max-width: 450px) {
    .not-found {
        flex-direction: column;
    }
    .not-found .message-box{
        text-align: center;
    }
} 
