.rewardcode {
    box-sizing: border-box;
    padding: 20px 0;
    font-family: sans-serif;
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .rewardcode .scratch-card {
    width: 300px;
    margin: 0 auto;
    padding: 25px 30px;
    border-radius: 10px;
    box-shadow: 0 0 15px 3px rgba(0, 0, 0, 0.3);
  }
  
  .rewardcode h2,
  .rewardcode h3 {
    text-align: center;
    color: #5a2ff5;
    text-transform: uppercase;
    font-weight: 400;
    margin: 0;
  }
  
  .rewardcode h2 {
    font-size: 64px;
  }
  
  .rewardcode h3 {
    font-size: 20px;
    margin-bottom: 20px;
  }
  
  .rewardcode p {
      text-align: center;
    font-weight: 600;
  }
  
  .rewardcode button{
    height: 50px;
    background-color: #5a2ff5;
    color: #fff;
    text-transform: uppercase;
    border: none;
    border-radius: 50px;
    margin: 0 auto;
    font-weight: 600;
    letter-spacing: 1px;
  }
  
  .rewardcode .scratch-container {
    overflow: hidden;
    position: relative;
    width: 300px;
    height: 60px;
    margin: 0 auto 20px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .rewardcode .scratch-canvas {
    position: absolute;
    top: 0;
    z-index: 10;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    cursor: grab;
    cursor: -webkit-grab;
  }
  
  .rewardcode .code {
    position: relative;
    visibility: hidden;
    margin: 0 auto;
    text-align: center;
    color: #5a2ff5;
    font-weight: 700;
    font-size: 1.2rem;
  }
  
  .rewardcode .fade-out {
    animation: fade-out 0.8s;
    -webkit-animation: fade-out 0.8s;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
  }
  
  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    
    100% {
      opacity: 0;
    }
  }


@media only screen and (max-width: 600px) {
    .rewardcode {
        min-height: unset;
        display: block;
        justify-content: unset;
        align-items: unset;
    }
  }