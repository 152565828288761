@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');

.match_display {
    overflow-x: hidden;
    height: 100vh;
    background: url(https://i.imgur.com/twess8p.jpg);
    background-size: cover;
    padding: 0;
    margin: 0;
    font-family: 'Poppins', sans-serif;
}

.match_display .header {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    box-shadow: none;
    background-color: #c62d29;
    position: fixed;
    height: 60px !important;
    overflow: hidden;
    z-index: 10;
}

.match_display .main {
    margin: 0 auto;
    display: block;
    height: 100%;
    margin-top: 60px;
}

.match_display .mainInner {
    display: block;
    height: 100%;
    width: 100%;
    text-align: center;
}

.match_display .mainInner div {
    display: block;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 1.25px;
    box-sizing: border-box;
}

#match_display #sidebarMenu {
    height: 100%;
    position: fixed;
    left: 0;
    width: 250px;
    margin-top: 60px;
    transform: translateX(-250px);
    transition: transform 250ms ease-in-out;
    background: #4e5e6c;
}

.match_display .sidebarMenuInner {
    margin: 0;
    padding: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.10);
}

.match_display .sidebarMenuInner li {
    list-style: none;
    color: #fff;
    text-transform: capatalize;
    font-weight: normal;
    padding: 11px 20px;
    cursor: pointer;
    border-bottom: 1px solid rgba(255, 255, 255, 0.10);
}

.match_display .sidebarMenuInner li i {
    width: 20px;
}

.match_display .sidebarMenuInner li span {
    display: block;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.50);
}

.match_display .sidebarMenuInner li a {
    color: #fff;
    text-transform: capitalize;
    font-weight: normal;
    cursor: pointer;
    text-decoration: none;
}

.match_display input[type="checkbox"]:checked~#sidebarMenu {
    transform: translateX(0);
}

.match_display input[type=checkbox] {
    transition: all 0.3s;
    box-sizing: border-box;
    display: none;
}

.match_display .sidebarIconToggle {
    transition: all 0.3s;
    box-sizing: border-box;
    cursor: pointer;
    position: absolute;
    z-index: 99;
    height: 100%;
    width: 100%;
    top: 22px;
    left: 15px;
    height: 22px;
    width: 22px;
}

.match_display .spinner {
    transition: all 0.3s;
    box-sizing: border-box;
    position: absolute;
    height: 3px;
    width: 100%;
    background-color: #fff;
}

.match_display .horizontal {
    transition: all 0.3s;
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 3px;
}

.match_display .diagonal.part-1 {
    position: relative;
    transition: all 0.3s;
    box-sizing: border-box;
    float: left;
}

.match_display .diagonal.part-2 {
    transition: all 0.3s;
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 3px;
}

.match_display input[type=checkbox]:checked~.sidebarIconToggle>.horizontal {
    transition: all 0.3s;
    box-sizing: border-box;
    opacity: 0;
}

.match_display input[type=checkbox]:checked~.sidebarIconToggle>.diagonal.part-1 {
    transition: all 0.3s;
    box-sizing: border-box;
    transform: rotate(135deg);
    margin-top: 8px;
}

.match_display input[type=checkbox]:checked~.sidebarIconToggle>.diagonal.part-2 {
    transition: all 0.3s;
    box-sizing: border-box;
    transform: rotate(-135deg);
    margin-top: -9px;
}

.match_display .game-wrap {
    padding: 20px;
    max-width: 300px;
    margin: 20px auto;
}

.match_display .game-thumb {
    position: relative;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, .3);
    width: 100%;
    margin-bottom: 20px;
    padding: 12px;
    border-radius: 6px;
}

.match_display .ribbon {
    position: absolute;
    bottom: -11px;
    font-size: 11px !important;
    background: rgba(220, 123, 3, 0.95);
    color: #fff;
    letter-spacing: 1px !important;
    width: 236px;
    border-radius: 122px !important;
    text-align: center;
    padding: 4px 2px;
}

.match_display .game-thumb img {
    max-width: 100%;
    width: 100%;
}

.match_display .bid-now-wrap {
    display: block;
    margin-top: 40px;
}

.match_display .bid-now-wrap a {
    display: block;
    background: #fff;
    text-transform: uppercase;
    text-decoration: none;
    padding: 10px 10px;
    border-radius: 100px;
    color: #c62d29;
    box-shadow: 0 17px 10px -10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all ease-in-out 300ms;
}

.match_display .bid-now-wrap a:hover {
    display: block;
    background: #c62d29;
    color: #fff;
    transform: translate(0, -1px);
}