.main-grid {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
}

.split-grid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    min-height: 80vh;
}

.split-grid>.container-box {
    padding-left: 7%;
}

.login-box {
    padding: 20px 10px;
    max-width: 400px;
    width: 100%;
}

footer {
    background: #607d8b;
    min-height: 60px;
}

.footer-column {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
}

@media screen and (max-width: 900px) {
    .split-grid {
        flex-direction: column;
        justify-content: center;
    }

    .split-grid>.container-box {
        padding-left: 16px;
        /* 16px from theme */
    }

    .container-box {
        text-align: center;
        display: flex;
        justify-content: center;
    }

    .small-hide {
        display: none;
    }
}