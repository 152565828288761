.contact-mid {
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact-mid .name{
    display: flex;
    flex-direction: row;
    /* justify-content: start; */
    align-items: center;
}

@media only screen and (max-width: 600px) {
    .contact-mid {
        min-height: unset;
        display: block;
        flex-direction: unset;
        justify-content: unset;
        align-items: unset;
    }
}