/* @import url('https://fonts.googleapis.com/css2?family=Red+Hat+Text:wght@400;700&display=swap'); */

.body-crouser {
  --color: #333;
  font-family: 'Red Hat Text', sans-serif;
}

.body-crouser .container {
  text-align: center;
  color: var(--color);
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("/public/ludo.png");
  background-repeat: no-repeat;
  /* background-size: cover; */
  background-position: center;

  /*  this is where the magic happens:  */
  background-attachment: fixed;
}

.body-crouser .flex-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 60vh;
}

.body-crouser h1 {
  font-weight: 700;
  font-size: 3rem;
}

.body-crouser p {
  max-width: 52ch;
  line-height: 1.5;
  padding: 1em;
}

.body-crouser a {
  display: inline-block;
  padding: 1em 3.5em;
  background-color: #0277bd;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 0.3em;
  font-weight: 700;
  letter-spacing: .5px;
  font-size: .875rem;
}

.body-crouser .blue {
  background-color: #0277bd;
}

.body-crouser .inverted_link {
  color: var(--color);
  background-color: white;
  margin-top: 10px;
}

.body-crouser .blank,
.body-crouser .other {
  width: 100%;
  min-height: 60vh;
  background-color: var(--color);
  color: white;
}

.body-crouser .second {
  background-image: url("/public/sunrise.jpg");
  background-attachment: fixed;
  min-height: 100vh;
}

.body-crouser h3 {
  font-weight: 700;
  font-size: 1.6rem;
  margin-top: 1em;
}

.body-crouser .second .item {
  width: 25%;
  min-width: 350px;
  max-width: 350px;
}

.body-crouser .card>a {
  margin-bottom: 2em;
}

.body-crouser .item {
  display: flex;
  max-width: 320px;
  background-color: white;
  flex-direction: column;
  align-items: center;
  border-radius: .5em;
  -webkit-box-shadow: 0px 29px 38px -15px rgba(0, 0, 0, 0.43);
  -moz-box-shadow: 0px 29px 38px -15px rgba(0, 0, 0, 0.43);
  box-shadow: 0px 29px 38px -15px rgba(0, 0, 0, 0.43);
}

.body-crouser .img {
  width: 90%;
  height: 200px;
  background-color: lightgrey;
  background-size: cover;
  background-position: center;
  margin-top: 20px;
  border-radius: .3em;
  ;
}

.body-crouser .img-first {
  background-image: url("/public/ludo_classic_1.jpg");
}

.body-crouser .img-second {
  background-image: url("/public/ludo_soon.png");
}

.body-crouser .img-third {
  background-image: url("/public/snake_ladder.png");
  background-color: rgb(233, 154, 133);
}

.body-crouser .second {
  flex-direction: row;
  gap: 1.6em;
}

@media only screen and (max-width: 760px) {
  .body-crouser .second {
    flex-direction: column;
    height: unset;
    padding: 20px 0;
  }

  .body-crouser .second .item {
    min-width: 90%;
  }

  .body-crouser .flex-body {
    min-height: unset;
  }
}