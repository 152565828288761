body,
html,
#app {
    margin: 0;
    width: 100%;
    min-height: 100vh;
    height: 100%;
}

#app {
    overflow: hidden;
    touch-action: pan-up;
    color: #ffffff;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-shadow: 0 0 5px #ffffff, 0 0 20px #000, 0 0 30px #000;
}

#app #hero {
    text-transform: uppercase;
    font-weight: bold;
}

#app a {
    margin-top: 0.5rem;
    display: inline-block;
    text-decoration: none;
    color: #fff;
}

#app canvas {
    display: block;
    position: fixed;
    z-index: -1;
    top: 0;
}