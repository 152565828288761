.chat-frame {
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.chat-frame>* {
    padding: 10px 0px;
}


@media only screen and (max-width: 600px) {
    .rewardcode {
        min-height: unset;
        display: block;
        flex-direction: unset;
        justify-content: unset;
        align-items: unset;
        text-align: unset;
    }
}